import React from 'react'

const About = () => (
  <>
  <h1>さいたま市のキリストを愛するクリスチャンの集まり</h1>
  <p>
　わたしたちはさいたま市で集まっているクリスチャンです。わたしたちは聖書の言葉に純粋に従うことを願い、わたしたちの主イエス・キリストを愛し、彼の御名の中で集まり、キリストの豊富を互いにエンジョイして、キリストのからだである教会を建造することを目標としています。
</p>
 
<p>
　聖書を「わたしたちの唯一無二の標準として」生活行動することを志しています。キリストはわたしたちの命であり、教会はわたしたちの生活です。
</p>
 
<p>
　さいたまに在る教会はわたしたちの名称ではなく、言うなれば説明にすぎません。わたしたちは埼玉市で集まっているクリスチャンの群れです。わたしたちは主イエス・キリストを信じている人をすべて兄弟姉妹として受け入れるだけでなく、クリスチャンでない方々の参加も温かく歓迎しております。
</p>
    <h1>初めて教会に来られる方へ</h1>
    <p>
　教会は、どんな人にも開かれています。まだクリスチャンでない方でも、少しでも聖書やキリストに興味のあるすべてに方を歓迎いたします。ほぼ毎日、クリスチャンの集まりがあります。聖書を学ぶ会もあります。また、日曜日には主を記念する集会がありますので、見学に来たい方にも開かれております。
</p><p>
　さいたま に在る教会は、正統なクリスチャンの集まりであり、キリストの教会です。エホバの証人、モルモン教、統一家庭連合（旧名：統一教会）などでお悩みの方は、どうぞお気軽にご相談ください。
</p> 
<div><h5>〒338-0002 埼玉県さいたま市中央区下落合２丁目１－４</h5>℡：048-708-3290</div>


<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3232.667726105598!2d139.63412411562425!3d35.88165878014809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018c1a4c9dbae45%3A0xb95dc09f66deeea5!2z44CSMzM4LTAwMDIg5Z-8546J55yM44GV44GE44Gf44G-5biC5Lit5aSu5Yy65LiL6JC95ZCI77yS5LiB55uu77yR4oiS77yU!5e0!3m2!1sja!2sjp!4v1639018049392!5m2!1sja!2sjp" width="600" height="450"  allowfullscreen="" loading="lazy"></iframe>

</>

)

export default About
